@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-filter {
        margin-bottom: 18px;
        .catalog-filter-holder {
            form {
                .filter-holder {
                    .filter-col {}
                }

                .sort-holder {
                    .sort {
                        padding-top: 1px;
                        margin: 0 -4px 0 0;
                        .sort-select {
                            margin-left: 7px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
