@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-detail {
        margin-bottom: 31px;
        .catalog-detail-holder {
            >.image {
                display: block;
                float: none;
                margin: 0 auto;
            }

            .info {
                width: 100%;
                float: none;
                padding: 0;
                margin: 42px 0 0;
                .desc {
                    box-sizing: border-box;
                    padding-right: 22%;
                }

                .item-option {
                    >.name {

                    }

                    .option-holder {

                        .cardboard-card {

                        	form {

                        		.form-group {
                        			.form-col {
                                        &.col-cardboard-enable {
                                            width: 29.6%;
                                        }
                                        &.col-cardboard-text {
                                            width: 70.4%;
                                        }
                        				.cardboard-enable {

                        					.form-field {
                                                margin-bottom: 4px;
                        						.checkcontainer {

                        							input {

                        							}

                        							.checkmark {

                        							}

                        							.name {
                                                        font-style: normal;
                                                        font-weight: 600;
                                                        font-size: 16px;
                                                        line-height: 130%;
                        							}
                        						}
                        					}

                        					.cardboard-image {

                        						img {

                        						}
                        					}
                        				}

                                        .cardboard-text {

                                        	.form-field {
                                        		.input {

                                        			.name {

                                        			}

                                        			textarea {
                                                        height: 122px;
                                        			}
                                        		}
                                        	}
                                        }
                        			}
                        		}
                        	}
                        }

                        .promo-note {
                            margin: 0 0 22px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
