.catalog-detail {
    display: inline-block;
    width: 100%;
    margin-bottom: 47px;
	.catalog-detail-holder {
        display: inline-block;
        width: 100%;
		>.image {
            display: inline-block;
            width: 520px;
            float: left;
            position: relative;
            .banners {
                left: 1px;
                z-index: 1000;
            }

			.connected-gallery {
                display: inline-block;
                width: 100%;
				.main-image-slider {
                    display: inline-block;
                    width: 100%;
                    height: 620px;
                    box-sizing: border-box;
                    border: 1px solid #DFE5EA;
                    margin-bottom: 25px;
					.nav {

					}

					.main-image {
                        height: 100%;
                        .image {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            box-sizing: border-box;
                            padding: 20px;
                            a {
                                display: flex;
                                width: 100%;
                                height: 100%;
                                justify-content: center;
                                align-items: center;
    							img {
                                    display: inline-block;
                                    max-width: 100%;
                                    max-height: 100%;
                                    min-width: 90%;
                                    min-height: 90%;
                                    object-fit: cover;
    							}
    						}
                        }
					}
				}

				.preview-image-slider {
                    position: relative;
                    box-sizing: border-box;
                    padding: 0 20px 0 30px;

                    .slick-slider {
                        width: calc(100% + 10px);
                        margin: -5px;
                        .slick-list {
                            .slick-track {
                                .slick-slide {
                                    padding: 0 5px;
                                    &.slick-current {
                                        .image {
                                            a {
                                                border: 1px solid $brand-primary;
                                                transition: all 300ms;
                                                img {
                                                    opacity: 1;
                                                    transition: all 300ms;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

					.prev, .next {
                        transform: translateY(calc(-50% - 1px));
                        display: none;
                        &.slick-arrow {
                            display: inline-block;
                        }
						svg {

							path {

							}
						}
					}

					.next {

					}

					.preview-images {

                        .image {
                            display: inline-block;
                            width: 100%;
                            height: 130px;
                            a {
                                display: flex;
                                width: 100%;
                                height: 100%;
                                justify-content: center;
                                align-items: center;
                                box-sizing: border-box;
                                border: 1px solid #DFE5EA;
                                padding: 8px;
                                transition: all 300ms;
                                &:hover {
                                    img {
                                        opacity: 1;
                                        transition: all 300ms;
                                    }
                                }
    							img {
                                    display: inline-block;
                                    max-width: 100%;
                                    max-height: 100%;
                                    min-width: 90%;
                                    min-height: 90%;
                                    object-fit: contain;
                                    opacity: 0.5;
                                    transition: all 300ms;
    							}
    						}
                        }
					}
				}
			}
		}

		.info {
            display: inline-block;
            width: calc(100% - 520px);
            float: left;
            box-sizing: border-box;
            padding-left: 50px;
			>.name {
                display: inline-block;
                width: 100%;
                margin-top: -5px;
                margin-bottom: 10px;
				h1, .h1 {
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 30px;
                    line-height: 150%;
                    margin: 0;
				}
			}

            .desc {
                display: inline-block;
                width: 100%;
                margin-bottom: 11px;
                p {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 150%;
                    margin: 0 0 10px;
                }
            }

            .item-option {
                display: inline-block;
                width: 100%;
                padding-bottom: 17px;
                border-bottom: 1px solid #DFE5EA;
                margin-bottom: 20px;
                >.name {
                    display: inline-block;
                    width: 100%;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 130%;
                    margin-bottom: 10px;
                    &.margin-left {
                        margin-left: 7px;
                    }
                }
                .option-holder {
                    display: inline-block;
                    width: 100%;

                    .sizes {
                        display: inline-block;
                        width: calc(100% + 7.5px);
                        margin-left: -7.5px;
        				.size {
                            display: inline-block;
                            float: left;
                            box-sizing: border-box;
                            padding: 0 7.5px;
                            width: 109px;
        					.btn {
                                width: 100%;
                                padding: 9px 0;
                                box-sizing: border-box;
                                text-align: center;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 130%;
                                border-radius: 20px;
                                background: transparent;
                                border: 1px solid #DFE5EA;
                                color: $gray;
                                &:hover {
                                    background: $brand-primary;
                                    border: 1px solid $brand-primary;
                                    color: white;
                                }
                                &.selected {
                                    border: 1px solid $brand-primary;
                                    background: transparent;
                                    color: $brand-primary;
                                    &:hover {
                                        border: 1px solid $brand-primary;
                                        background: transparent;
                                        color: $brand-primary;
                                        cursor: default;
                                    }
                                }
                                &.disabled {
                                    background: #F0F4F6;
                                    border: 1px solid #F0F4F6;
                                    color: $gray-light;
                                    &:hover {
                                        background: #F0F4F6;
                                        border: 1px solid #F0F4F6;
                                        color: $gray-light;
                                        cursor: default;
                                    }
                                }
        					}
        				}
        			}

                    .amount-block {
                        display: inline-block;
                        float: left;
                        width: 205px;
                        .icon {
                            display: inline-block;
                            float: left;
                            width: 35px;
                            padding-top: 7px;
                            svg {

                            }
                        }

                        .amount {
                            display: inline-block;
                            float: left;
                            width: 170px;
                            .amount-counter {

                                .decrease, .increase  {

                                    svg {

                                    }
                                }

                                input {

                                }
                            }
                        }
                    }

                    .bucket-size {
                        display: inline-block;
                        float: left;
                        margin-left: 11px;
                        .equals {
                            display: inline-block;
                            float: left;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 150%;
                            padding-top: 10px;
                            margin-right: 12px;
                        }
            			.size {
                            display: inline-block;
                            float: left;
                            position: relative;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 130%;
                            color: $gray;
                            margin-right: 4px;
                            padding-top: 7px;
                            .arrows {
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                bottom: -14px;
                                svg {

                                }
                            }
            			}

            			.popup-note {
                            display: inline-block;
                            float: left;
                            padding-top: 6px;
            			}
            		}

                    .wrappeings {
                        display: inline-block;
                        width: calc(100% + 10px);
                        margin-left: -5px;
                    	.wrapping {
                            display: inline-block;;
                            float: left;
                            width: 180px;
                            box-sizing: border-box;
                            padding: 0 5px;
                    		a {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                border: 1px solid #DFE5EA;
                                box-sizing: border-box;
                                border-radius: 40px;
                                padding: 5px 5px 3px;
                                &:hover {
                                    .icon {
                                        img {
                                            opacity: 1;
                                            transition: all 300ms;
                                        }
                                    }

                                    .text {
                                        color: $gray;
                                        transition: all 300ms;
                                    }
                                }
                                &.selected {
                                    border: 1px solid $brand-primary;
                                    .icon {
                                        img {
                                            opacity: 1;
                                            transition: all 300ms;
                                        }
                                    }

                                    .text {
                                        color: $brand-primary;
                                        transition: all 300ms;
                                    }
                                    &:hover {
                                        cursor: default;
                                    }
                                }
                    			.icon {
                                    display: inline-block;
                                    float: left;
                                    width: 40px;
                                    height: 40px;
                                    overflow: hidden;
                                    border-radius: 50%;
                    				img {
                                        display: inline-block;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        opacity: 0.5;
                                        transition: all 300ms;
                    				}
                    			}

                    			.text {
                                    display: inline-block;
                                    float: left;
                                    width: calc(100% - 40px);
                                    box-sizing: border-box;
                                    padding-left: 5px;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 130%;
                                    color: $gray-light;
                                    transition: all 300ms;
                    			}
                    		}
                    	}
                    }

                    .cardboard-card {

                    	form {

                    		.form-group {
                                width: calc(100% + 20px);
                                margin-left: -10px;
                    			.form-col {
                                    padding: 0 10px;
                                    &.col-cardboard-enable {
                                        width: 37.5%;
                                    }
                                    &.col-cardboard-text {
                                        width: 62.5%;
                                    }
                    				.cardboard-enable {

                    					.form-field {
                                            margin-bottom: 14px;
                    						.checkcontainer {

                    							input {

                    							}

                    							.checkmark {

                    							}

                    							.name {

                    							}
                    						}
                    					}

                    					.cardboard-image {

                    						img {
                                                width: 179px;
                    						}
                    					}
                    				}

                                    .cardboard-text {

                                    	.form-field {
                                            margin-bottom: 0;
                                    		.input {

                                    			.name {

                                    			}

                                    			textarea {
                                                    height: 127px;
                                                    padding: 15px 10px 0 14px;
                                    			}
                                    		}
                                    	}
                                    }
                    			}
                    		}
                    	}
                    }
                }
            }

            .promo-note {
                display: inline-block;
                border: 1px dashed #00914C;
                box-sizing: border-box;
                background: #F5FCF6;
                margin: -5px 0 22px;
            	.promo-note-holder {
                    box-sizing: border-box;
                    padding: 10px 30px;
                    display: flex;
                    align-items: center;
                    width: 428px;
                    min-height: 78px;
            		.icon {
                        margin-right: 20px;
            			svg {
            			}
            		}

            		.text {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 130%;
            		}
            	}
            }

            .cart-actions {
                display: inline-block;
                width: 100%;
            	.price {
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 11px;
            		.name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 34px;
                        margin-right: 11px;
            		}

            		.price-values {
                        display: flex;
                        align-items: flex-end;
            			.old-value {
                            font-family: $accent_font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 30px;
                            color: $gray;
                            text-decoration: line-through;
                            margin-right: 14px;
            			}

            			.value {
                            font-family: $accent_font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 30px;
                            line-height: 40px;
            			}
            		}
            	}

            	.actions {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 19px;
            		.in-cart {
                        display: inline-block;
                        float: left;
                        width: 200px;
                        margin-right: 10px;
                        .btn {
                            width: 100%;
                            border-radius: 30px;
                            text-align: center;
                            padding: 11px 0;
                            font-family: $accent_font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 18px;
                            line-height: 150%;
                        }
            		}

            		.fast-buy {
                        display: inline-block;
                        float: left;
                        width: 200px;
                        .btn {
                            width: 100%;
                            border-radius: 30px;
                            text-align: center;
                            padding: 11px 0;
                            font-family: $accent_font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 18px;
                            line-height: 150%;
                        }
            		}
            	}

            	.notes {
                    display: inline-block;
                    width: 100%;
                    .delivery-time {
                        display: inline-block;
                        width: 100%;
                        margin: -10px 0 18px;
                        .icon {
                            display: inline-block;
                            float: left;
                            margin-right: 10px;
                        }
                        .text {
                            display: inline-block;
                            float: left;
                            width: 400px;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                        }
                    }
            		.about-payment, .about-delivery {
                        display: inline-block;
                        width: 100%;
                        margin-bottom: 8px;
            			a {
                            display: inline-block;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            color: $gray-base;
                            &:hover {
                                color: $brand-primary;
                                .text {
                                    text-decoration-color: transparent;
                                    transition: all 300ms;
                                }
                            }
            				.icon {
                                display: inline-block;
                                float: left;
                                margin-right: 10px;
            					svg {

            					}
            				}

            				.text {
                                display: inline-block;
                                float: left;
                                text-decoration: underline;
                                text-decoration-color: currentColor;
                                transition: all 300ms;
            				}
            			}
            		}
            	}
            }
		}
	}
}

@import "../../media/tablet/includes/catalog/detail";
@import "../../media/mobile/includes/catalog/detail";
