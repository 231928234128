@media (max-width: $screen-sm) {
    // sm-view
    .catalog-text {
        .catalog-text-holder {
            h2, .h2 {
                font-style: normal;
                font-weight: normal;
                font-size: 21px;
                line-height: 150%;
                margin-bottom: 10px;
                margin-top: 10px;
            }

            p {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150%;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
