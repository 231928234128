@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-text {
        margin: 0 0 31px;
    }
}

@media (max-width: $screen-md) {
    // md-view
}
