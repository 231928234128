.catalog-filter {
    display: inline-block;
    width: 100%;
    margin-bottom: 12px;
	.catalog-filter-holder {
        display: inline-block;
        width: 100%;
		form {
            display: inline-block;
            width: 100%;
			.filter-holder {
                display: inline-block;
                width: calc(100% + 20px);
                margin-left: -10px;
				.filter-col {
                    display: inline-block;
                    float: left;
                    width: 200px;
                    box-sizing: border-box;
                    padding: 0 10px;
                    &.big {
                        width: 369px;
                    }
					.form-field {
                        display: inline-block;
                        width: 100%;
						.select {

							.name {

							}

							.selectric-select {

							}
						}
					}
                    a.mobile-filter-link {
                        display: inline-block;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        text-transform: uppercase;
                        color: $gray-base;
                        .icon {
                            display: inline-block;
                            float: left;
                            margin-right: 10px;
                        }

                        .text {
                            display: inline-block;
                            float: left;
                        }
                    }
				}
			}

			.sort-holder {
                display: inline-block;
                float: right;
                padding: 0 10px;
				.sort {
                    display: inline-block;
                    float: left;
                    padding-top: 18px;
					.heading {
                        display: inline-block;
                        float: left;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 150%;
                        margin-right: 2px;
					}

					.sort-select {
                        display: inline-block;
                        float: left;
                        margin-left: 11px;
						a {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            color: $gray-base;
                            &:hover {
                                color: $brand-primary;
                            }
                            &.selected {
                                color: $brand-primary;
                                .arrow {
                                    opacity: 1;
                                }
                            }
                            &.reverse {
                                .arrow {
                                    svg {
                                        transform: rotate(180deg);
                                        transition: all 300ms;
                                    }
                                }
                            }

							.text {
                                display: inline-block;
                                float: left;
							}

							.arrow {
                                display: inline-block;
                                float: left;
                                opacity: 0;
                                width: 8px;
                                height: 11px;
                                padding-top: 7px;
                                margin-left: 5px;
								svg {
                                    width: 100%;
                                    height: 100%;
                                    transition: all 300ms;
								}
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/filter";
@import "../../media/mobile/includes/catalog/filter";
