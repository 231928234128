.catalog-text {
    display: inline-block;
    width: 100%;
    margin: 0 0 69px;
	.catalog-text-holder {
        display: inline-block;
        width: 100%;
		h2, .h2 {
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 150%;
            margin-bottom: 18px;
		}

		p {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
		}
	}
}

@import "../../media/tablet/includes/catalog/text";
@import "../../media/mobile/includes/catalog/text";
