@media (max-width: $screen-sm) {
    // sm-view
    .catalog-detail {
        overflow: hidden;
        margin-bottom: 26px;
        .catalog-detail-holder {
            >.image {
                display: block;
                width: 100%;
                float: none;
                margin: 0 auto;

                .connected-gallery {
                    .main-image-slider {
                        height: 107vw;
                        margin-bottom: 16px;
                        .main-image {
                            .image {
                                padding: 0 27px;
                            }
                        }
                    }

                    .preview-image-slider {
                        padding: 0 19.5vw 0 0;
                        .slick-slider {
                            .slick-list {
                                overflow: visible;
                            }
                        }
                    }
                }
            }

            .info {
                margin: 26px 0 0;
                >.name {
                    h1, .h1 {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 21px;
                        line-height: 150%;
                    }
                }

                .desc {
                    padding-right: 0;
                }

                .item-option {
                    >.name {

                    }

                    .option-holder {

                        .sizes {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            width: calc(100% + 15px);
                            .size {
                                width: 100px;
                            }
                        }

                        .amount-block {}

                        .bucket-size {}

                        .wrappeings {
                            .wrapping {
                                width: 100%;
                                margin: 0 0 10px;
                                &:last-of-type {
                                    margin: 0;
                                }
                                a {
                                    .icon {}

                                    .text {
                                        br {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .cardboard-card {

                            form {
                                .form-group {
                                    width: 100%;
                                    margin-left: 0;
                                    .form-col {
                                        padding: 0;
                                        &.col-cardboard-enable {
                                            width: 100%;
                                        }
                                        &.col-cardboard-text {
                                            width: 100%;
                                        }

                                        .cardboard-enable {
                                            margin: 0 0 21px;
                                        }
                                    }
                                }
                            }

                        }
                    }
                }

                .promo-note {
                    margin: -4px 0 22px;
                    .promo-note-holder {
                        width: 100%;
                        padding: 10px 19px;
                        .icon {
                            margin-right: 20px;

                            svg {
                                width: 30px;
                                height: 30px;
                            }

                        }

                        .text {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 130%;
                        }
                    }
                }

                .cart-actions {
                    .price {

                    }

                    .actions {
                        .in-cart {
                            width: 100%;
                            margin: 0 0 10px;
                            .btn {

                            }
                        }

                        .fast-buy {
                            width: 100%;
                        }
                    }

                    .notes {
                        .delivery-time {
                            margin: -7px 0 20px;
                            .icon {
                                margin-right: 8px;
                            }

                            .text {
                                width: calc(100% - 26px);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
