@media (max-width: $screen-sm) {
    // sm-view
    .catalog-filter {
        .catalog-filter-holder {
            form {
                .filter-holder {
                    .filter-col {
                        width: 38%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
